import React, { Component } from 'react';
import styled from 'styled-components';
import { getComplimentaryColor } from '../../utils/colors';

export default function withComplimentaryColors(WrappedComponent) {
  const WrappedWithStyle = styled(WrappedComponent)`
    color: ${({ color, backgroundColor }) =>
      color || getComplimentaryColor(backgroundColor)};
    background: ${({ backgroundColor }) => backgroundColor && backgroundColor};
  `;
  // eslint-disable-next-line react/display-name
  return class extends Component {
    render() {
      return <WrappedWithStyle {...this.props} />;
    }
  };
}

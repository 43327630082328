import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { isSafari, isIOS, IOSView } from 'react-device-detect';

export default function Video({ className, src, poster }) {
  const videoRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [autoPlay, setAutoPlay] = useState(true);
  const [inView, setInView] = useState(false);

  useLayoutEffect(() => {
    const { current: videoElement } = videoRef;
    videoElement.setAttribute('muted', '');
    // Autoplay on Safari as it doesn't support triggering video events other than with touch interaction
    if (isSafari || isIOS || IOSView) setAutoPlay(true);
    // In non-shit browsers, if the video is loaded and in the viewport, play that baby
    if (loaded && inView) {
      videoElement.play();
    } else {
      // Only enable pausing if the videos are not on
      // the screen at the same time.
      // videoElement.pause();
    }
    // Altering window size reloads the video source
  }, [loaded, inView]);

  return (
    <Waypoint
      onEnter={() => setInView(true)}
      onLeave={() => setInView(false)}
      scrollableAncestor="window"
    >
      <video
        poster={poster}
        key={src} // Forces video to reload on source change
        src={src}
        ref={videoRef}
        playsInline
        preload="true"
        className={className}
        disableremoteplayback="true"
        loop
        muted
        autoPlay={autoPlay}
        onLoadStart={() => setLoaded(false)}
        onLoadedData={() => setLoaded(true)}
        css="width: 100%"
      />
    </Waypoint>
  );
}

Video.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  poster: PropTypes.string,
};
